import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import vant from 'vant';
import MetaInfo from 'vue-meta-info';
import Vue3CountryIntl from 'vue3-country-intl';
import axios from 'axios';
import VueAxios from 'vue-axios';
import qs from 'qs';
import vueI18n from '@/lang/';
import { Toast } from 'vant';
import { Dialog } from 'vant';
import { ActionSheet } from 'vant';
import { Swipe, SwipeItem } from 'vant';
import { NoticeBar } from 'vant';
import { Tabbar, TabbarItem } from 'vant';


import 'vue3-country-intl/lib/vue3-country-intl.css'
import 'vant/lib/index.css';

import './assets/public/rem.js';

const app = createApp(App).use(router).use(vant).use(MetaInfo).use(VueAxios,axios).use(qs).use(vueI18n).use(Toast).use(Dialog).use(ActionSheet).use(Swipe).use(SwipeItem).use(NoticeBar).use(Tabbar).use(TabbarItem).component(Vue3CountryIntl.name, Vue3CountryIntl)
app.config.globalProperties.Axios = axios;
app.config.globalProperties.Router = router;
app.config.globalProperties.Toast = Toast;
app.config.globalProperties.Dialog = Dialog;
app.config.globalProperties.$api = 'https://api.m--smartstore.com';
app.mount('#app');