export default {
    	邮箱: "이메일",
	手机号码: "전화번호",
	密码: "비밀번호",
	登录: "로그인",
	还没账户: "아직 계좌가 없다",
	忘记密码: "비밀번호 분실",
	立即注册: "즉시 등록",
	HNFT账户登录: "HNFT 계좌 로그인",
	请输入邮箱: "이메일을 입력하세요",
	邮箱格式错误: "이메일 형식 잘못",
	请输入手机号码: "전화번호를 입력하세요",
	请输入密码: "비밀번호를 입력하세요",
	请选择: "선택해 주세요",
	HNFT注册: "HNFT 등록",
	验证码: "인증 번호",
	推荐码: "추천 번호",
	确定: "확인",
	获取验证码: "인증 번호를 획득",
	邮箱格式错误: "이메일 형식 잘못",
	请输入手机号码: "전화번호를 입력하세요",
	请输入邮箱: "이메일을 입력하세요",
	请输入正确的邮箱: "정확한 이메일을 입력하세요",
	请输入密码: "비밀번호를 입력하세요",
	登录密码只能为6至20位数字或字母: "로그인 비밀번호는 6~20자리 숫자 또는 알파벳만 가능합니다.",
	重置密码: "비밀번호 리셋",
	新密码: "새로운 비밀번호",
	预售中: "예약 판매 중",
	交易列表: "거래 리스트",
	收藏品: "소장품",
	限量: "한정 수량",
	份: "세트",
	用户指南: "사용자 안내서",
	什么是NFT: "NFT는 무엇입니까",
	首页: "홈페이지",
	发现: "뉴스",
	藏宝室: "투자내역",
	我的: "내정보",
	搜索您要的精彩: "원하는 멋진 것을 검색한다.",
	我的收藏: "나의 소장",
	环球猎手顶级NFT艺术展览尽在HNFT: "유니버설 헌터 최상급 NFT 아트쇼는 모두 HNFT에 있습니다.",
	成为发行方: "발행인 되다",
	成为发行方您可以在HNFT发行NFT: "발행인이 되면 HNFT에서 NFT를 발행할 수 있습니다.",
	升级商家: "상점 승급",
	成为商家您可以挂交易订单: "상점이 되면 거래 주문을 걸 수 있습니다.",
	公告头条: "헤드라인",
	关于发行: "발행에 대해",
	拍卖中心: "경매 센터",
	铸造中心: "주조 센터",
	发行机构: "발행 기관",
	创作NFT: "NFT 창작",
	"加入NFT发行方，您可以创建属于自己的NFT": "NFT 발행인에 가입하면 자신의 NFT를 만들 수 있습니다.",
	入驻NFT发行: "NFT 발행에 입점",
	开始: "시작",
	订单记录: "주문 기록",
	全部: "전부",
	进行中: "진행 중",
	已失效: "미체결",
	已完成: "이미 완성",
	预售倒计时: "예약판매 카운트다운",
	支付倒计时: "지불 카운트다운",
	份额: "배당",
	需支付: "필요 지불",
	立即支付: "즉시 지불",
	立即查看: "즉식 확인",
	订单号: "주문 번호",
	升级商家: "상점 승급",
	成为发行方: "발행인 되다",
	"请输入USDT(TRC20)地址": "USDT(TRC20) 주소를 입력하세요",
	请输入USDT地址: "USDT 주소를 입력하세요",
	确认: "확인",
	取消: "취소",
	信息: "정보",
	我的收藏: "나의 소장",
	我的NFT: "니의 NFT",
	余额: "잔금",
	充值: "충전",
	提现: "현금 인출",
	我的资产: "나의 재산",
	团队列表: "팀 리스트",
	邀请会员: "회원 초대",
	账户绑定: "계좌 바인딩",
	账户明细: "계좌 내역",
	联系客服: "고객 서비스 연락",
	退出登录: "로그아웃",
	编辑资料: "자료 편집",
	头像: "두상",
	昵称: "애칭",
	账号: "계좌",
	推荐码: "추천 번호",
	安全设置: "안전 설정",
	交易密码: "거래 비밀번호",
	"昵称长度必须为5-15个字符": "애칭 길이는 반드시 5-15자여야 합니다.",
	提交: "제출",
	登录密码: "로그인 비밀번호",
	新交易密码: "새로운 거래 비밀번호",
	消息列表: "정보 리스트",
	暂无信息: "현재 정보가 없습니다",
	类别: "분류",
	点击选择: "선택 클릭",
	地址: "주소",
	钱包充值: "지갑 충전",
	输入金额: "금액을 입력하세요",
	上传凭证: "증빙서류 업로드",
	复制成功: "복제 성공",
	金额错误: "금액 잘못",
	请上传凭证: "증빙서류를 업로드하세요",
	充值记录: "충전 기록",
	没有更多了: "더 이상 없습니다",
	审核通过: "심사 통과",
	审核中: "심사 중",
	驳回: "반박",
	提现: "현금 인출",
	开户姓名: "계좌 개설 성명",
	提现金额: "인출 금액",
	输入金额: "금액을 입력하세요",
	最大可提金额: "최대 인출 금액",
	提现记录: "현금 인출 기록",
	团队列表: "팀 리스트",
	注册时间: "등록 기간",
	我的资产: "나의 재산",
	资产列表: "재산 리스트",
	Swap货币兑换: "Swap 화폐 교환",
	货币兑换: "화폐 교환",
	兑换金额: "교환 금액",
	您最多可兑换: "당신은 최대 가능 교환",
	余额不足: "잔금이 부족",
	立即兑换: "즉시 교환",
	长按图片保存: "그림을 길게 눌러 저장합니다",
	分享推广: "공유 프로모션",
	保存二维码: "QR 코드를 저장합니다",
	扫一扫来玩HNFT赚取收益: "싹쓸이해서 HNFT를 놀아 수익을 법니다.",
	我的邀请码: "나의 초대 코드",
	立即分享给好友: "지금 바로 친구한테 공유하세요",
	复制链接: "링크 복사",
	复制邀请码: "초대 코드 복사",
	请输入开户姓名: "계좌 개설 성명을 입력하세요",
	账户余额: "계좌 잔금",
	收入: "수입",
	支出: "지출",
	联系客服: "고객 서비스 연락",
	在线客服: "온라인 고객 서비스",
	客服1: "고객 서비스 1",
	客服2: "고객 서비스 2",
	打开: "오픈",
	我的收藏: "나의 소장",
	申请商家: "상점을 신청",
	确认入驻商家: "상점에 입점을 확인",
	我同意: "동의",
	升级请联系客服: "업그레이드하면 고객 서비스를 연락하세요",
	NFT搜索: "NFT 검색",
	请输入搜索关键词: "검색 키워드를 입력하세요",
	最新: "최신",
	最热: "제일 핫",
	创作NFT: "NFT 창작",
	NFT文件: "NFT 소류",
	NFT名称: "NFT 명칭",
	简介: "소개",
	请输入简介: "소개를 입력하세요",
	合集: "합집",
	点击选择主链: "메인 체인을 선택하려면 클릭하세요.",
	立即创建: "즉시 창건",
	申请须知: "신청 안내서",
	"申请NFT发行方需联系HNFT客服，申请成功，即可创建属于您自己的NFT作品!": "NFT발행을 신청하신 분은 HNFT 고객 서비스에 연락하셔야 합니다. 신청이 성공하면 당신만의 NFT 작품을 만들 수 있습니다!",
	历史发行: "역사 발행",
	藏品类目: "소장품 분류 목록",
	盲盒类目: "블라인드 박스 분류 목록",
	支付详情: "지불 상세한 상황",
	请输入支付密码: "지불 비밀번호를 입력하세요",
	预售倒计时: "예약판매 카운트다운",
	支付倒计时: "지불 카운트다운",
	订单号: "주문 번호",
	购买类型: "구매 유형",
	下单时间: "주문 기간",
	预售订单: "예약판매 주문",
	支付时间: "지불 기간",
	等待支付: "지불 기다림",
	已失效: "미체결",
	合计: "합계",
	立即支付: "즉시 지불",
	NFT详情: "NFT 상세한 상황",
	NFT信息: "NFT 정보",
	预售价格: "예약판매 가격",
	购买类型: "구매 유형",
	预售订单: "예약판매 주문",
	开始时间: "시작 기간",
	发行方信息: "발행인 정보",
	作品特性: "작품 특성",
	合约地址: "계약 주소",
	作品描述: "작품 묘사",
	买入: "매입",
	卖出: "매출",
	立即下单: "즉시 주문",
	持有数量: "보유 수량",
	我要卖: "제가 매출하겠습니다",
	升级商家: "상점 승급",
	请输入卖出份数: "매출 부수를 입력하세요",
	卖出NFT: "NFT 매출",
	获得金额: "금액 획득",
	出售: "매각",
	挂单数量: "청구 수량",
	拥有者: "보유자",
	最少卖出: "최소 매출",
	我要买: "제가 사겠습니다",
	买入NFT: "NFT 매입",
	请输入买入份数: "매입 부수를 입력하세요",
	需支付: "필요 지불",
	购买: "구매",
	最少买入: "최소 매입",
	每份: "한 세트",
	确认下单: "주문 확인",
	"预售进行中,下单完成后请等待预售结束,预售结束后需要您在24小时内支付下单的金额!": "예약판매 진행중이며, 주문완료 후 예약완료를 기다려주시고, 예약완료 후 24시간 이내에 주문하신 금액을 결제하셔야 합니다.",
	暂未对普通会员开放: "일반 회원에게는 아직 개방하지 않았습니다",
	限购数量: "수량 제한",
		数量: "수량",
		剩余: "남은",
		发布方信息: "출판사 정보",
		挂单: "보류 중인 주문",
		交易订单: "무역 주문",
		当前持有: "현재 개최 중",
		NFT总份数: "총 NFT 사본 수",
		选择买入或卖出: "구매 또는 판매를 선택하세요",
		请输入挂单价格: "지정가 주문 가격을 입력하세요",
		请输入数量: "수량을 입력해주세요.",
		最低: "가장 낮은",
		请输入最低份数: "최소 사본 수를 입력하십시오.",
		最高: "제일 높은",
		请输入最高份数: "최대 복사 매수를 입력하세요.",
		交易通知邮箱: "거래 알림 메일함",
		请输入邮箱: "이메일을 입력해주세요",
		类型: "유형",
		价格: "가격",
		立即提交: "지금 제출",
		撤销: "취소",
		已撤销: "취소됨",
		已完成: "체결 완료",
		支付金额: "결제 금액",
		地址: "주소",
		凭证: "자격증",
		买入订单: "구매 주문",
		卖出订单: "매도 주문",
		支付货币: "결제 통화",
		状态: "상태",
		确认下单: "주문 확인",
		'预售进行中,下单完成后请6小时内支付给商家!': "선판매 진행중이니, 주문 후 6시간 이내에 판매자에게 결제해주세요!",
		参数错误: "매개변수 오류",
		该操作需要申请为HNFT商家: "이 작업은 HNFT 판매자로 적용되어야 합니다.",
		碎片化顶级NFT艺术展览尽在HNFT: "조각난 최고의 NFT 미술 전시회는 모두 HNFT에 있습니다",
		NFT碎片: "NFT 조각",
		将您持有的NFT分布成碎片: "보유하고 있는 NFT를 샤드로 배포하세요",
		成为商家: "상인이 되다",
		成为商家您可以将您持有的NFT拆分: "판매자가 되어 보유한 NFT를 분할할 수 있습니다.",
		入驻HNFT商家: "HNFT 가맹점에 정착",
		申请须知: "애플리케이션 노트",
		请提交区块链应用合法执照或者证件: "블록체인 적용에 대한 법적 면허증이나 증명서를 제출해주세요.",
		开始: "시작",
		关于NFT: "NFT 소개",
		创作中心: "경매",
		合成中心: "종합센터",
		HNFT商家: "상인 계좌",
		HNFT碎片: "조각화",
		碎片: "파편",
		版税: "로열티",	
		版税兑换: "로열티 교환",
		转移NFT: "NFT 전송",
		转入NFT: "NFT로 전송",
		版税明细: "로열티 세부정보",
		联系邮箱: "이메일 연락처",
		客服邮箱: "고객 서비스 이메일",
		申请成为商家: "판매자 계정이 되기 위해 신청하세요",	
		公司名称: "회사 이름",	
		请输入公司: "회사를 입력해주세요",
		国家: "국가",	
		请输入国家: "국가를 입력해주세요",	
		营业执照: "영업 허가증",	
		法人证件正面: "법인증명서 앞면",	
		法人证件反面: "법인증명서 뒷면",	
		请等待审核: "리뷰를 기다려주세요",	
		请输入正确参数: "올바른 매개변수를 입력하세요",	
		请上传完整图片: "풀영상 올려주세요",	
		申请商家须知: "가맹점 신청 안내",	
		请提交区块链应用合法执照或者证件: "블록체인 적용에 대한 법적 면허증이나 증명서를 제출해주세요.",	
		我的碎片: "내 조각들",
		持有: "잡고 있다",
		合成: "합성",
		碎片数量不足: "샤드 수가 부족함",
		合成碎片: "합성 단편",
		'合成碎片不可取消,是否确认合成': "합성 확인 여부는 조각 결합을 취소할 수 없습니다.",
		兑换NFT: "NFT 교환",
		请输入兑换份数: "매수를 입력해 주세요",
		需支付版税: "로열티가 지불됩니다",
		兑换: "교환",
		点击选择NFT: "NFT를 선택하려면 클릭하세요.",
		请输入地址: "주소를 입력해주세요",
		转移记录: "이체 기록",
		NFT转入记录: "NFT 전송 기록",
		NFT转入: "NFT 이체",
		请输入名称: "이름을 입력해주세요",
		'请输入特性,请使用逗号隔开': "특성을 입력하세요. 쉼표를 사용하여 구분하세요.",
		请输入描述: "설명을 입력해주세요",
		上传NFT文件: "NFT 파일 업로드",
		上传转入凭证: "이전 증명서 업로드",
		请上传NFT文件: "NFT 파일을 업로드해주세요",
		请输入交易密码: "거래 비밀번호를 입력해주세요",
		'暂未设置交易密码,请设置交易密码': "아직 거래 비밀번호가 설정되지 않았습니다. 거래 비밀번호를 설정하세요.",
		地址修改: "주소 수정",
		'扫一扫来玩HNFT,交易0版税,推荐更有版税佣金': "스캔하여 HNFT 재생, 거래에 대한 로열티 0, 추천에 대한 추가 로열티",
		忘记交易密码: "거래 비밀번호를 잊어버렸습니다",
		重复密码: "비밀번호를 반복하세요",
		新登录密码: "새로운 로그인 비밀번호",
		重复登录密码: "로그인 비밀번호를 반복하세요",
		查看该作者: "저자 보기",
		                待确认: "확인될",
			立即确认: "지금 확인",
			请等待确认: "확인을 기다려주세요",
			等待确认: "확인을 기다리는 중",
			提示: "힌트",
			'您已确认无误收到款项，是否确认': "정상적으로 결제가 이루어졌음을 확인하셨습니다. 확인하시겠습니까?",
			提示: "힌트",
			点击选择NFT文件: "NFT 파일을 선택하려면 클릭하세요.",
			请输入拆分份额: "분할 공유를 입력하세요.",
			限购: "구매 한도",
			每人限购份额: "1인당 구매한도",
			币种: "통화",
			点击选择币种: "통화를 선택하려면 클릭하세요.",
			请输入价格: "가격을 입력하세요.",
			'倒计时(小时)': "'카운트다운(시간)",
			点击选择倒计时: "카운트다운을 선택하려면 클릭하세요.",
			TG聊天链接: "TG 채팅 링크",
			请输入链接: "링크를 입력해주세요",
			Line聊天链接: "라인채팅 링크",
			WhatsApp聊天链接: "WhatsApp 채팅 링크",
			制作NFT碎片: "NFT 조각 만들기",
			发布记录: "발매기록",
			信息: "정보",
			您需要支付: "당신은 지불해야합니다 ",
			'未收到款?': "결제를 받지 못하셨나요?",
			'您未收到款,客服将在2小时内处理您的订单,立即申请客服处理!': "결제를 받지 못했습니다. 고객 서비스에서 2시간 이내에 주문을 처리합니다. 즉시 고객 서비스 처리를 신청하세요!",
			联系商家: "판매자에게 연락하기",
			倒计时: "카운트다운",
			卖出范围: "판매 범위",
			买入范围: "구매 범위",
			该操作需要申请为HNFT商家: "이 작업을 수행하려면 HNFT 판매자로 신청해야 합니다.",
			'申请商家需要持有HNFT Cash pledge，详情请联系官方客服或邮箱': '신청자는 HNFT Cash 담보를 보유하고 있어야 하며 자세한 내용은 공식 고객센터 또는 이메일로 문의하시기 바랍니다.',
			质押HNFT: 'HNFT 스테이킹',
			质押记录: '서약기록',
			赎回成功: '상환 성공',
			进行中: '진행 중',
			审核中: '검토중',
			质押失败: '서약 실패',
			解除质押: '약속 취소',
			奖励: '상',
			创建时间: '생성 시간',
			结束时间: '종료 시간',
			赎回: '구원',
			HNFT名称: 'HNFT 이름',
			预售价格: '사전 판매 가격',
			质押数量: '약속 수량',
			请输入数量: '수량을 입력해주세요',
			全部: '모두',
			可用数量: '가능 수량',
			'碎片奖励(天)': '조각 보상(일)',
			备注: '주목',
			质押时间: '공약시간',
			计息时间: '이자 발생 시간',
			赎回时间: '상환 시간',
			立即提交: '지금 제출',
			'时间(天)': '시간(일)',
			质押确认: '서약 확인',
			'质押您可以获得版税和手续费奖励，质押不可取消，请确认!': '스테이킹을 통해 로열티 및 수수료 보상을 받을 수 있습니다. 서약은 취소가 불가능하니 꼭 확인해주세요!',
			持有: '잡고 있다',
			质押: '약속',
			价格趋势: '가격 추세',
			持仓市值: '포지션 시장가치',
			今日盈亏: '오늘의 손익',
			总盈亏: '총손익',
			持仓成本: '보유비용',
			碎片数量: '조각 수',
			质押碎片: '서약 샤드',
			质押收入: '공약소득',
			名称: '이름',
			实时价: '가격',
			市值: '시장 가치',
			质押: '서약',
			持仓: '보유재산',
			我的持仓: '내 포지션',
			查看: '확인',
			实名认证: 'KYC 인증',
			请等待实名审核: '실명리뷰를 기다려주세요',
			确认: '확인하다',
			注意事项: '지침',
			'需持有HNFT Handling fee数量': '보유해야 하는 HNFT Handling fee 금액',
			上传身份证明: '신원 증명 업로드',
			上传持有凭证: '보유증명서 업로드',
			历史: '역사',
			操作: '작동하다',
			交易: ' 거래',
			一键买入: '호가 매수',
			一键卖出: '호가 매도',
			最低回购价: '최저 환매 가격',
			一键买卖: '원클릭 구매 및 판매',
			买入价格: '구매 가격',
			预估回购价格: '예상 환매 가격',
			回购价格比例: '환매가격비율',
			买入数量: '수량 구매',
			请输入买入数量: '구매수량을 입력해주세요',
			卖出数量: '판매 수량',
			请输入卖出数量: '판매수량을 입력해주세요',
			回购价格: '환매 가격',
			数量不足: '수량 부족',
			信用分: '신용 점수',
			使用版税兑换: '로열티 상환 사용',
			'使用HNFT Royalties兑换': 'HNFT 로열티로 사용',
			'需支付版税/碎片': '로열티/부분 지급',
			'OTC只支持USDT(ERC)选项': 'OTC는 USDT(ERC) 옵션만 지원합니다.',
			进行中: '진행 중',
			请联系右上角与商家沟通交易: '거래를 위해 판매자와 소통하려면 오른쪽 상단에 문의하세요.',
			订单已完成: '주문 완료',
			订单已取消: '주문이 취소됨',
			交易信息: '거래정보',
			单价: '단가',
			购买数量: '구매 수량',
			支付金额: '결제 금액',
			商户名称: '비즈니스 이름',
			订单ID: '주문 아이디',
			时间: '시간',
			类型: '유형',
			转入地址: '송금받는 주소',
			进行中: '진행 중',
			已取消: '취소 된',
			已完成: '체결 완료',
			OTC交易: 'OTC 거래',
			'HNFT的OTC交易对接的是主流交易所商家，为您提供便利的法币交易需求。': 'HNFT의 OTC 거래는 주류 거래소 가맹점과 연결되어 귀하에게 편리한 법정 통화 거래 요구를 제공합니다.',
			OTC钱包: 'OTC 지갑',
			卖出到OTC余额: 'OTC 잔액으로 판매',
			OTC余额支付: 'OTC 잔액 결제',
			OTC余额: 'OTC 잔액',
			C2C转账: 'C2C 전송',
			C2C需要您手动转账: 'C2C에서는 수동으로 송금해야 합니다.',
			确认支付: '결제 확인',
			'OTC-Pay将使用您的OTC余额支付，请确认！': 'OTC-Pay는 귀하의 OTC 잔액을 사용하여 결제합니다. 확인해주세요!',
			OTC快捷交易: 'OTC 빠른 거래',
			快捷交易能够让您以当前OTC市场中的最优价格成交: '빠른 거래를 통해 현재 OTC 시장에서 가장 좋은 가격으로 거래를 완료할 수 있습니다.',
			购买: '구입하다',
			出售: '팔다',
			我要支付: '나는 지불하고 싶다',
			我将收到: '나는 받을 것이다',
			参考价格: '제안 가격',
			如何使用OTC: 'OTC 이용 방법',
			OTC记录: 'OTC 기록',
			交易数量: '거래수',
			请先KYC再进行OTC操作: 'OTC 운영을 진행하기 전에 먼저 KYC를 진행하세요.',
			上传身份证正面: '신분증 앞면 업로드',
			上传身份证反面: '신분증 뒷면 업로드',
			请上传身份证正面: '신분증 앞면을 업로드해주세요.',
			请上传身份证反面: '신분증 뒷면을 업로드해주세요.',
			交易对象ID: '거래 개체 ID',
			请选择支付币种: '결제통화를 선택해주세요',
			买入碎片数量: '구매한 수량',
			交易金额: '거래 금액',
			五分钟前: '오분 전',
			'祝贺您收到幸运订单，请查看！': '행운의 주문을 축하드립니다. 꼭 확인해 주세요!',
			'OTC验证金额': 'OTC 검증금액',
			升级商家: '업그레이드 가맹점',
			请联系客服: '고객 서비스에 문의하십시오',
			确认入驻商家: '가맹점 정착 확인',
			我同意: '나는 동의한다',
			成为发行方: '발급자 되기',
			成为发行方您可以发行属于您自己的NFT: '발행자가 되어 자체 NFT를 발행할 수 있습니다.',
			NFT发行方: 'NFT 발행자',
			'申请成为NFT发行方，需要您有相应的区块链合法资质，详情请联系官方客服或邮箱': 'NFT 발행자가 되기 위해서는 해당하는 블록체인 관련 법적 자격을 갖추고 있어야 합니다. 자세한 내용은 공식 고객센터나 이메일로 문의하시기 바랍니다.',
			'您的OTC钱包需持有': 'OTC 지갑에 보유해야 할 금액',
			指数交易: '지수 거래',
			未成交: '거래 없음',
			进行中: '진행 중',
			已完成: '완전한',
			强制平仓: '강제청산',
			撤单: '주문 취소',
			当前委托: '현재 주문',
			历史委托: '역사적 명령',
			买入: '구입',
			卖出: '팔다',
			倍杠杆: '지렛대',
			保证金: '여유',
			开仓价: '거래 가격',
			当前价: '현재 가격',
			持仓数量: '포지션 수량',
			预计盈亏: '추정 손익',
			创建时间: '생성 시간',
			止损价: '손실 중지 가격',
			止盈价: '이익실현 가격',
			手续费: '수수료',
			添加保证金: '여백 추가',
			平仓: '포지션 마감',
			立即下单: '지금 주문하세요',
			平仓价格: '정산가격',
			平仓时间: '완료 시간',
			买涨: '구입',
			买跌: '팔다',
			OTC余额: 'OTC 잔액',
			杠杆: '지렛대',
			'NFT-GI预估数量': 'NFT-GI 추정 수량',
			预估手续费: '예상 수수료',
			'买入/开多': '매수/오픈 매수',
			'卖出/开空': '매도/공매도',
			设置价格: '가격 설정',
			请输入价格: '가격을 입력해주세요',
			请输入增加的保证金: '증가된 마진을 입력해 주세요',
			请输入买入USDT金额: 'USDT를 구매할 금액을 입력하세요',
			最低增加保证金为10USDT: '최소 추가 증거금은 10 USDT입니다.',
			订单信息: '주문 정보',
			'您是否立即撤单，撤单不可恢复，请确认!': '즉시 주문을 취소하시겠습니까? 취소하면 복원할 수 없습니다. 확인해 주세요.',
			'立即按照当前价格平仓，请确认!': '현재 가격으로 즉시 포지션을 청산하세요. 확인해주세요!',
			'最小交易金额为100USDT': '최소 거래 금액은 100USDT입니다.',
			盈亏: '이익과 손실',
			NFT指数: 'NFT 지수',
			交易明细: '상세 거래 내역',
			NFT借贷: 'NFT 대출',
			借贷: '대출',
			借贷入口: '대출입구',
			还贷入口: '대출상환입구',
			借款申请: '대출신청',
			借款金额: '대출 금액',
			'请输入申请金额(USDT)': '신청 금액(USDT)을 입력하세요.',
			账户: '계정',
			申请将获取您的账户信息: '신청하면 귀하의 계정 정보를 얻을 수 있습니다',
			立即申请: '즉시 적용',
			借款记录: '대출기록',
			借款金额: '대출 금액',
			创建时间: '생성 시간',
			完成: '마치다',
			进行中: '진행 중',
			联系客服获取借款合同: '대출 계약을 얻으려면 고객 서비스에 문의하십시오.',
			OTC还贷: 'OTC 대출 상환',
			'该交易将为您购买的USDT,直接还款': '이 거래는 귀하가 구매한 USDT를 귀하에게 직접 상환합니다.',
			我要还款: '나는 보답하고 싶다',
			'当前账户未实名，是否立即实名认证。': '현재계좌는 실명이 아닙니다. 즉시 실명인증을 하시겠습니까?',
			请输入验证码: '인증번호를 입력해주세요',
			请输入邀请码: '초대코드를 입력해주세요',
			请输入新密码: '새로운 비밀번호를 입력해주세요',
			我的服务: '내 서비스',
			'当前账户未实名，是否立即实名认证。': '현재계좌는 실명이 아닙니다. 즉시 실명인증을 하시겠습니까?',
			'请输入USDT(ERC20)地址': 'USDT(ERC20) 주소를 입력해주세요',
}