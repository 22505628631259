<template>

  <!-- vue3.0配置 -->
<router-view v-slot="{ Component, route }">
  <keep-alive>
    <component :is="Component" :key="route.name" v-if="route.meta.keepAlive" />
  </keep-alive>
  <component :is="Component" :key="route.name" v-if="!route.meta.keepAlive" />
</router-view>

</template>
<script>
  export default {
     metaInfo: {
      title: 'HNFT',
       meta: [
		{ 
			name:'apple-mobile-web-app-capable', content:'yes',
		},
		{
			name:'apple-touch-fullscreen', content:'yes',
		},
		{
			name:'apple-mobile-web-app-status-bar-style', content:'black',
		},
		{
			name:'format-detection', content:'telephone=no',
		},
		{
			name:'viewport', content:'width=device-width, initial-scale=1.0, user-scalable=no,viewport-fit=cover',
		},
	]
    },
	setup (){
		var lang = localStorage.getItem('lang');
		if(!lang){
			localStorage.setItem('lang','en-us');
		}
		
		
	},
  }
</script>
<style>
*{
  margin: 0;
  padding: 0;
  font-family: '微软雅黑';
}
html, body, #app {
  width: 100%;
  height: 100%;
  /* background-color: #f7f7f7; */
}
i, s {
  font-style: normal;
  text-decoration: none;
}
.clearfix:before,.clearfix:after {
  content: "";
  display: table;
}
.clearfix:after {
  clear: both;
}
.clearfix {
  *zoom: 1; /* IE/7/6 */
}
.biaoju-btn::before {
  content: " ";
  position: absolute;
  top: 50%;
  left: 50%;
  opacity: 0;
  width: 100%;
  height: 100%;
  border: inherit;
  border-color: #000;
  background-color: #000;
  border-radius: inherit;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.biaoju-btn:focus, .biaoju-btn:active {
  color: red;
}
.vue-country-intl-input{
	background-color: #171b24 !important;
	background: #171b24 !important;
	border-radius: 1rem !important;
	height: 1rem !important;
}
.vue-country-intl-input .country-intl-input{
	height: 1rem !important;
	border: none !important;
	background-color: #171b24 !important;
	background: #171b24 !important;
	border-radius: 1rem !important;
}
.vue-country-intl-input .country-intl-label{
	height: 1rem !important;
	color: #eee;
	background-color: #171b24 !important;
	background: #171b24 !important;
	border-radius: 1rem !important;
}
.vue-country-intl-input .country-intl-input-wrap{
	height: 1rem !important;
	border-radius: 1rem !important;
}
.vue-country-list-wrap{
	overflow-x: hidden !important;
}
.vue-country-intl-input .vue-country-list-wrap{
	min-width: 4.5rem !important;
}
.vue-country-intl-input .country-intl-label{
	font-size: 18px !important;
}
.vue-country-intl-input .country-intl-label span{
	    margin-top: 0.02rem;
}
.van-nav-bar.van-hairline--bottom.van-safe-area-top{
	background: #202231;color: #fff;
}
.van-nav-bar__title{
	color: #fff !important;
}
.van-nav-bar .van-icon{
	color: #fff !important;
}
.van-nav-bar__arrow{
	font-size: 0.5rem !important;
}
/* 	.van-submit-bar__button--danger{
		background: linear-gradient(45deg,#AA21F1,#52EAC0) !important;
	}
	.van-button--danger{
		background: linear-gradient(45deg,#AA21F1,#52EAC0) !important;
	}
	.van-submit-bar__text span:first-child{
		display: none !important;
	} */
.van-card__price{
	color: #fff !important;
}
.van-field__left-icon .van-icon, .van-field__right-icon .van-icon{
	font-size: 30px;
}
.van-field__clear, .van-field__right-icon{
	width: 0.7rem !important;
}
:root{
	--van-field-icon-size: 22px !important;
}
#wrapper canvas{
	width: 5.5rem !important;
	height: auto !important;
	border-radius: 0.2rem;
}
/* .van-overlay{
	opacity: 0.3 !important;
} */
 .textms .van-tabs__content img{
	width: 100% !important;
	height: auto !important;
}
.van-search{
	background: #202231 !important;
}
.van-search__action{
	color: #ccc !important;
}

.van-nav-bar.van-hairline--bottom.van-safe-area-top {
    background: #2c4760;
    color: #fff;
}
</style>

