import { createI18n } from 'vue-i18n'
import zh from './zh-cn'
import en from './en-us'
import vi from './vi-vn'
import kr from './kr-ko'

const i18n = createI18n({
     legacy: false, // 让 setup 函数可以通过 t 访问
     globalInjection: true, // 让 template 可以像 vue2 那样使用 $t 来访问
     locale: localStorage.getItem('lang') || 'en-us',
     fallbackLocale: localStorage.getItem('lang') || 'en-us',
   messages:{
      zh,
      en,
	  vi,
	  kr,
   }
});
export default i18n;